export const NAVIGATION_ITEMS = [
  {
    title: 'Blog',
    to: '/posts'
  },
  {
    title: 'About',
    to: '/about-me'
  }
];
